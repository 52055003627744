import $ from 'jquery'
import React from 'react'

const Apikey = ({ apikey, manualApikey, sentinel }) => {
  return (
    <div className='text-white' id='txt-apikey' onClick={manualApikey}>
      {apikey}
      <br />R {React.version} - jQ {$().jquery}
      <br />
      {sentinel != null ? sentinel.type : ''}
    </div>
  )
}

export default Apikey
