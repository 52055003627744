import React, { useEffect, useState } from 'react'
import TopNavbar from '../components/topNavbar'
import axios, { AxiosError } from 'axios'
import * as Constants from '../src/constants'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MainPage from '../pages/mainPage'
import PhotosPage from '../pages/photosPage'
import CameraPage from '../pages/cameraPage'

const App = () => {
  const [apikey, setApikey] = useState(localStorage.getItem('apikey'))
  const [machineName, setMachineName] = useState('')
  const [alert, setAlert] = useState('')
  const [sentinel, setSentinel] = useState(null)
  const [oldData, setOldData] = useState(true)
  const [transactionsFilters, setTransactionsFilters] = useState([])

  let wakeLock = null

  useEffect(() => {
    fetchMachines(apikey)
    requireWakeLock()
  }, [])

  function requireWakeLock () {
    wakeLock = navigator.wakeLock
      .request('screen')
      .then(res => setSentinel(res))
    console.log('wakelock promise : %o', wakeLock)
  }

  const fetchMachines = apikey => {
    axios
      .get(Constants.serverUrl + '/api/manage.php', {
        params: {
          function: 'getmachines',
          apikey: apikey
        }
      })
      .then(response => {
        processMachines(response.data)
      })
      .catch(error => {
        if (error.response && error.response.status == 401)
          setAlert('Tablette non autorisée')
      })
  }

  function processMachines (data) {
    console.log('process machine')
    let filters = []
    if ('filters' in data) {
      filters = data['filters']
    }

    console.log('set filters and machine name')
    console.log('machine name %o', data.name)
    console.log('filters %o', filters)

    setMachineName(data.name)
    setTransactionsFilters(filters)
  }

  const manualApikey = e => {
    e.preventDefault()

    let userKey = prompt('apikey', apikey ? apikey : '')

    if (userKey != null && userKey.length == 13) {
      localStorage.setItem('apikey', userKey)
      setApikey(userKey)
      fetchMachines(userKey)
    }
  }

  return (
    <BrowserRouter>
      <TopNavbar
        oldData={oldData}
        apikey={apikey}
        manualApikey={manualApikey}
        machineName={machineName}
        sentinel={sentinel}
      />

      <Routes>
        <Route
          path='/'
          element={
            <MainPage
              apikey={apikey}
              setOldData={setOldData}
              transactionsFilters={transactionsFilters}
            />
          }
        />

        <Route path='/photos' element={<PhotosPage apikey={apikey} />} />
        <Route path='/camera' element={<CameraPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
