import { CardGroup, Row, Col } from 'react-bootstrap'
import Transaction from './transaction'

const Transactions = ({
  transactions,
  onToggleOwnership,
  onValidate,
  onAddImage
}) => {
  return (
    <>
      <Row xs={1} md={2} xl={3} className='g-4 pt-4'>
        {transactions.map(t => (
          <Col>
            <Transaction
              key={t.id}
              transaction={t}
              onToggleOwnership={onToggleOwnership}
              onValidate={onValidate}
              onAddImage={onAddImage}
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Transactions
