import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import { useLocation, Link } from 'react-router-dom'
import Apikey from './apikey'
import Machine from './machine'
import Heure from './heure'
import * as Constants from '../src/constants'

const TopNavbar = ({
  oldData,
  apikey,
  manualApikey,
  machineName,
  sentinel
}) => {
  const location = useLocation()

  return (
    <Navbar fixed='top' bg='dark' variant='dark' expand='sm'>
      <Container fluid>
        <Navbar.Brand>
          <i className='fi fi-bs-eye'></i>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='64'
            height='64'
            className='me-4'
            viewBox='0 0 16 16'
            fill={oldData ? 'red' : 'white'}
          >
            {oldData ? (
              <path d='M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z' />
            ) : (
              <>
                <path d='M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z' />
                <path d='M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z' />
              </>
            )}
          </svg>
          <Navbar.Text>{Constants.appTitle}</Navbar.Text>
        </Navbar.Brand>

        {location.pathname != '/' && (
          <Navbar.Collapse className='justify-content-between'>
            <Link to='/' className='btn btn-primary btn-lg'>
              Retour page principale
            </Link>
          </Navbar.Collapse>
        )}

        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text className='h2 text-white mb-0 pe-4'>
            <Heure />
          </Navbar.Text>
          <Navbar.Text className='h2 text-warning mb-0 pe-4'>
            <Machine name={machineName} />
          </Navbar.Text>
          <Navbar.Text>
            <Apikey
              apikey={apikey}
              manualApikey={manualApikey}
              sentinel={sentinel}
            />
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default TopNavbar
