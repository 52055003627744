import * as Constants from '../src/constants'
import PropTypes from 'prop-types'
import { Image, Button, Alert } from 'react-bootstrap'
import axios, { AxiosError } from 'axios'
import { useState } from 'react'

const ImagePreview = props => {
  const [progressText, setProgressText] = useState('')
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)

  /** images_uuid must be an array */
  const addImages = (apikey, id, id_lieu, images_uuid) => {
    axios
      .post(Constants.serverUrl + '/api/recv.php', {
        apikey: apikey,
        data_type: 'addImages',
        data: [{ id: id, id_lieu: id_lieu, images: images_uuid }]
      })
      .then(response => {
        console.log('OK %o', response)
        setProgressText('Photo complétement enregistrée')
        setSaved(true)
      })
      .catch(error => {
        console.log('OK %o', error)
        setProgressText("Erreur pendant l'attribution de l'image ", error)
      })
  }

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1)
      n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
  }

  const saveImage = (apikey, dataUri, id, id_lieu) => {
    const file = dataURLtoFile(dataUri, 'newfile')
    const data = new FormData()
    data.append('images[]', file, file.name)
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    setProgressText("Envoi de l'image sur le serveur")
    setSaving(true)

    axios
      .postForm(Constants.imagesProxyUrl + '/api/imagesproxy.php', data, config)
      .then(response => {
        console.log('response %o', response)
        console.log('uuid ' + response.data.uuid[0])
        setProgressText("Attribution de l'image au bulletin correspondant")
        addImages(apikey, id, id_lieu, response.data.uuid)
      })
      .catch(error => {
        console.log('error %o', error)
        setProgressText('Erreur de transfert ', error)
        setSaving(false)
      })
  }

  return (
    <>
      <div className='d-flex flex-row justify-content-start align-items-center'>
        {!saving && (
          <>
            <div className='p-2'>
              <Button
                variant='danger'
                size='lg'
                onClick={() => props.setDataUri('')}
              >
                Recommencer
              </Button>
            </div>

            <div className='p-2'>
              <Button
                variant='success'
                size='lg'
                onClick={() =>
                  saveImage(
                    props.apikey,
                    props.dataUri,
                    props.id,
                    props.id_lieu
                  )
                }
              >
                Enregistrer la photo
              </Button>
            </div>
          </>
        )}
        {saved && (
          <>
            <div className='p-2'>
              <Button
                variant='primary'
                size='lg'
                onClick={() => {
                  props.setDataUri('')
                  setSaved(false)
                  setSaving(false)
                }}
              >
                Ajouter une autre photo
              </Button>
            </div>
          </>
        )}

        <div className='p-2 flex-grow-1'>
          <Alert variant='info' className='mb-0'>
            Etat : {progressText}
          </Alert>
        </div>
      </div>
      <Image src={props.dataUri} fluid={true} />{' '}
    </>
  )
}

ImagePreview.propTypes = {
  dataUri: PropTypes.string
}

export default ImagePreview
