import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import LogoSVG from './logoSVG'

export default class Transaction extends Component {
  constructor (props) {
    super(props)
    //console.log('creation Transaction props %o', props)
    this.audioUrl = require('url:../ping.mp3')
    this.sound = new Audio(this.audioUrl)
  }

  componentDidMount () {
    this.sound.play()
  }

  btnVariant () {
    if (this.props.transaction.owner == '') return 'success'

    if (this.props.transaction.owner == 'self') return 'danger'

    return 'warning'
  }

  btnDisabled () {
    if (
      this.props.transaction.owner != '' &&
      this.props.transaction.owner != 'self'
    )
      return true
    else return false
  }

  btnText () {
    if (this.props.transaction.owner == 'self') return 'Libérer'

    if (this.props.transaction.owner == '') return "S'en occuper"

    return 'En chargement'
  }

  render () {
    return (
      <>
        <Card style={{ width: '22rem' }}>
          <Card.Header>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div>
                <h1>{this.props.transaction.camion}</h1>
              </div>
              <div className='d-flex flex-row'>
                {
                  {
                    CHARGE: (
                      <>
                        <LogoSVG letter='C' color='#13eb2a' />{' '}
                        {this.props.transaction.with_decharge == true && (
                          <LogoSVG letter='D' color='#eb1316' />
                        )}
                      </>
                    ),
                    DECHARGE: <LogoSVG letter='D' color='#eb1316' />,
                    default: <LogoSVG letter='?' color='#aeaeae' />
                  }[this.props.transaction.transaction]
                }
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Card.Title>
              {this.props.transaction.quantity} {this.props.transaction.article}
            </Card.Title>
            <Card.Subtitle></Card.Subtitle>
            <p className='mb-0'>{this.props.transaction.client}</p>
            <p>{this.props.transaction.chantier}</p>

            <div className='d-flex flex-row justify-content-between align-content-center align-items-center'>
              <div>
                {this.props.transaction.owner_description == ''
                  ? 'Pas de machine attribuée'
                  : this.props.transaction.owner_description}
              </div>

              <div>
                <Button
                  disabled={this.btnDisabled()}
                  variant={this.btnVariant()}
                  onClick={() => {
                    this.props.onToggleOwnership(
                      this.props.transaction.id,
                      this.props.transaction.id_lieu
                    )
                  }}
                >
                  {this.btnText()}
                </Button>
              </div>
            </div>

            {!this.btnDisabled() && (
              <Button
                variant='success'
                onClick={() =>
                  this.props.onValidate(
                    this.props.transaction.id,
                    this.props.transaction.id_lieu
                  )
                }
              >
                Valider le chargement
              </Button>
            )}
          </Card.Body>
          <Card.Footer>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <div>
                <h4>
                  <mark>{this.props.transaction.datetime}</mark>
                </h4>
              </div>
              <div>
                <Link
                  to='/photos'
                  state={{
                    images: this.props.transaction.images,
                    id: this.props.transaction.id,
                    id_lieu: this.props.transaction.id_lieu
                    //onAddImage: this.props.onAddImage
                  }}
                >
                  {this.props.transaction.images.length <= 1 ? (
                    <>{this.props.transaction.images.length} photo</>
                  ) : (
                    <>{this.props.transaction.images.length} photos</>
                  )}
                </Link>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </>
    )
  }
}
