import React, { useState, useEffect } from 'react'

const Heure = () => {
  const [currentTime, setCurrentTime] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      let date = new Date()
      setCurrentTime(date.toLocaleTimeString('fr-CH'))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return <>{currentTime}</>
}

export default Heure
