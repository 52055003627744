import { useState, useEffect } from 'react'
import { Carousel, Container, Button } from 'react-bootstrap'
import * as Constants from '../src/constants'
import { useLocation } from 'react-router-dom'
import Camera, { FACING_MODES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import ImagePreview from '../components/imagePreview'

const CameraPage = () => {
  const [dataUri, setDataUri] = useState('')

  const location = useLocation()

  function handleTakePhoto (dataUri) {
    setDataUri(dataUri)
  }

  return (
    <Container fluid='xl' className='mt-4'>
      {dataUri ? (
        <>
          <ImagePreview
            dataUri={dataUri}
            setDataUri={setDataUri}
            id={location.state.id}
            id_lieu={location.state.id_lieu}
            apikey={location.state.apikey}
          />
        </>
      ) : (
        <Camera
          onTakePhoto={dataUri => {
            handleTakePhoto(dataUri)
          }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          isMaxResolution={true}
          imageCompression={0.6}
        />
      )}
    </Container>
  )
}

export default CameraPage
