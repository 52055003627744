import { useState, useEffect } from 'react'
import { Carousel, Container } from 'react-bootstrap'
import * as Constants from '../src/constants'
import { useLocation, Link } from 'react-router-dom'

const PhotosPage = props => {
  const location = useLocation()

  return (
    <Container fluid='xl' className='mt-4'>
      <div className='d-flex justify-content-start'>
        <Link
          to='/camera'
          role='button'
          className='btn btn-primary btn-lg mb-4'
          state={{
            images: location.state.images,
            id: location.state.id,
            id_lieu: location.state.id_lieu,
            apikey: props.apikey
          }}
        >
          Ajouter une photo
        </Link>
      </div>

      {location.state.images.length > 0 ? (
        <Carousel interval={null} touch={false}>
          {location.state.images.map(f => (
            <Carousel.Item>
              <img
                className='d-block w-100'
                style={{ minHeight: 200 }}
                src={
                  Constants.imagesProxyUrl +
                  '/api/manage.php?apikey=' +
                  props.apikey +
                  '&function=getimage&uuid=' +
                  f
                }
              />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div className='d-flex justify-content-center'>
          <h1>pas de photo disponible</h1>
        </div>
      )}
    </Container>
  )
}

export default PhotosPage
