import React from 'react'

const LogoSVG = ({ letter, color }) => {
  return (
    <svg width='36' height='36'>
      <circle cx='18' cy='18' r='16' fill={color} />
      <text
        x='50%'
        y='50%'
        textAnchor='middle'
        fill='white'
        fontSize='20px'
        fontWeight='bold'
        fontFamily='Arial'
        dy='.4em'
      >
        {letter}
      </text>
    </svg>
  )
}

export default LogoSVG
