import Transactions from '../components/transactions'
import { useState, useEffect } from 'react'
import { Container, Alert } from 'react-bootstrap'
import * as Constants from '../src/constants'
import axios, { AxiosError } from 'axios'

const MainPage = ({ apikey, setOldData, transactionsFilters }) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    let id
    const fetchTransactions = async () => {
      const res = await axios
        .get(Constants.serverUrl + '/api/manage.php', {
          params: { apikey: apikey, function: 'gettransactions' }
        })
        .catch(error => {
          console.log('fetchTransaction error %o', error)
          setTransactions([])
          if (error.code != 'ERR_NETWORK')
            error.response &&
              console.log('Error status code ', error.response.status)
        })

      if (res) {
        if (firstLoad) setFirstLoad(false)
        if(res.data.data != null) {
        res.data.data.forEach(rewriteTransaction)
        processTransactions(
          res.data.datetime_update,
          res.data.data,
          transactionsFilters
        )
        }
      }

      id = setTimeout(fetchTransactions, 3000)
    }

    fetchTransactions()

    return () => clearTimeout(id)
  }, [apikey, transactionsFilters])

  const filterData = (filters, dataToFilter) => {
    //Group all filters by fields name
    let fields = []

    filters.forEach(filter => {
      if (!fields.includes(filter.field)) {
        fields.push(filter.field)
        fields[filter.field] = [filter.value]
      } else fields[filter.field].push(filter.value)
    })

    let filteredData = dataToFilter

    // Filter data For each field of filter
    fields.forEach(field => {
      filteredData = filteredData.filter(item =>
        fields[field].includes(item[field])
      )
    })

    return filteredData
  }

  function rewriteTransaction (t) {
    if (t.owner == apikey) t.owner = 'self'
  }

  /**
   * Create or remove html elements from document according to the data and filters.
   * @param {} data
   * @returns
   */
  function processTransactions (updateDatetime, data, filters) {
    //Calculate time difference in seconds
    let dateData = new Date(updateDatetime)
    let dateNow = new Date()
    let timeDiff = (dateNow.getTime() - dateData.getTime()) / 1000

    if (timeDiff > Constants.oldDataTimeout) {
      setOldData(true)
      setTransactions([])
      return
    }

    setOldData(false)

    //Filtering and sorting data
    let filteredData = filterData(filters, data)
    filteredData.sort((a, b) => b.epoch - a.epoch)
    setTransactions(filteredData)
  }

  function validate (id, id_lieu) {
    axios
      .post(Constants.serverUrl + '/api/recv.php', {
        apikey: apikey,
        data_type: 'validation',
        data: [{ id: id, id_lieu: id_lieu }]
      })
      .then(response => {})
      .catch(error => {
        if (error.response && error.response.status == 401)
          setAlert('Tablette non autorisée')
      })
  }

  function toggleOwnership (id, id_lieu) {
    axios
      .post(Constants.serverUrl + '/api/recv.php', {
        apikey: apikey,
        data_type: 'toggleOwnership',
        data: [{ id: id, id_lieu: id_lieu }]
      })
      .then(response => {})
      .catch(error => {
        if (error.response && error.response.status == 401)
          setAlert('Tablette non autorisée')
      })
  }

  return (
    <>
      {firstLoad && (
        <Container fluid='xl' className='mt-4'>
          <Alert variant='danger'>Chargement des données</Alert>
        </Container>
      )}
      <Container fluid='xl'>
        {transactions.length > 0 && (
          <Transactions
            transactions={transactions}
            onToggleOwnership={toggleOwnership}
            onValidate={validate}
          />
        )}
      </Container>
    </>
  )
}

export default MainPage
